.section {
	position: relative;
	z-index: 0;
	text-align: center;
	.background_diamonds {
		position: relative;
		&:before {
			top: 0;
		}
	}
	h1 {
		font-size: 3.000em;
		padding-top: 0.083em;
		margin-bottom: 0.625em;
	}
	p {
		font-size: 1.125em;
		margin-bottom: 3.500em;
	}
}
@media (min-width: 768px) {
	.section {
		.background_diamonds {
			&:before {
				content: "";
				width: 9.813em;
				height: 9.813em;
				background: url("/static/img/diamond-element.svg");
				background-size: 100% 100%;
				position: absolute;
				top: 3.313em;
				left: 0;
				z-index: 5;
				top: -8em;
			}
			&:after {
				content: "";
				width: 4.438em;
				height: 4.313em;
				background: url("/static/img/diamond-element-2.svg");
				background-size: 100% 100%;
				position: absolute;
				bottom: 5.000em;
				right: 0;
				z-index: 5;
				bottom: -8em;
			}
		}
		padding-top: 6.438em;
		margin-bottom: 11.313em;
		padding-top: 8.438em;
		margin-bottom: unset;
		padding-bottom: 2em;
	}
}
@media (max-width: 767.98px) {
	.section {
		h1 {
			font-size: 1.750em;
		}
	}
}
