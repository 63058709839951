.section {
    position: relative;
    z-index: 0;
    // background: #05272f;
    // background: -webkit-gradient(linear, left top, left bottom, from(#05272f), color-stop(90%, #002b34), to(#031820));
    // background: linear-gradient(180deg, #05272f 0%, #002b34 90%, #031820 100%);
    // padding-top: 14.688em;
    // min-height: 57.125em;
    overflow-x: hidden;
    position: relative;
    max-width: 5752px;
    @media screen and (min-width: 5751px) {
        margin-left: auto;
        margin-right: auto;
    }
    .container {
        z-index: 4;
        position: relative;
        @media screen and (min-width: 5751px) {
        }
        .text {
            color: #ffffff;
            font-weight: 600;
            margin-bottom: 17.563em;
            h1 {
                font-weight: 600;
                color: #ffffff;
                margin-bottom: 0.281em;
            }
            h2 {
                font-size: 2em;
                color: #00e2c5;
                font-weight: 300;
            }
            .btn_primary {
                /*
				margin-top: 2.857em;
				font-size: 0.875em;
				width: 226px;
				*/
                padding-left: unset;
                padding-right: unset;
                margin-top: 1.357em;
                font-size: 1.475em;
                padding: 0.5em 0em;
                width: 100%;
                @media screen and (min-width: 569px) {
                    width: 16.143em;
                }
            }
        }
    }
    .bottom_container {
        .section_extra {
            position: absolute;
            top: 0;
            z-index: 1;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            max-width: 1440px;
            height: 100%;
            .img_2 {
                position: absolute;
                right: -23.625em;
                top: 2em;
            }
            .img_4 {
                position: absolute;
                right: 21.188em;
                top: 28.438em;
            }
            .img_5 {
                position: absolute;
                left: -8em;
                top: 31.438em;
            }
            .img_1,
            .img_1_1 {
                position: absolute;
                top: 32.688em;
                left: -46.875em;
            }
            .img_1 {
                height: 519px;
                width: 3264px;
            }
            .img_1_1 {
                height: 225px;
                width: 3276px;
            }
            .img_6 {
                position: absolute;
                right: 28.25em;
                top: 6.375em;
            }
            .img_3 {
                position: absolute;
                right: 11.063em;
                bottom: 4.188em;
            }
        }
        .bottom_inner {
            width: 100%;
            position: relative;
            padding-top: 43.25em;
            z-index: 3;
            .img_bg {
                position: absolute;
                bottom: 0;
                left: 50%;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                overflow: hidden;
                > * {
                    bottom: -0.5em;
                }
            }
        }
    }
    .text {
        width: 36.688em;
        padding: 0;
        margin-bottom: 26.563em;
        h1 {
            font-size: 4em;
        }
    }
    .background {
        background: #05272f;
        background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(#05272f),
            color-stop(90%, #002b34),
            to(#031820)
        );
        background: linear-gradient(
            180deg,
            #05272f 0%,
            #002b34 90%,
            #031820 100%
        );
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 4px;
        right: 0;
    }
}
@media (min-width: 768px) {
    .section {
        .container {
            padding-top: 14.688em;
            .text {
                margin-left: 4.813em;
            }
        }
    }
}
@media (max-width: 767.98px) {
    .section {
        .container {
            .text {
                padding-left: 1.063em;
                padding-right: 1.063em;
                max-width: 100%;
                h1 {
                    font-size: 1.75em;
                    text-align: center;
                }
                h2 {
                    font-size: 1em;
                    text-align: center;
                }
                .btn_primary {
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
        .bottom_container {
            .bottom_inner {
                padding-top: 23.438em;
            }
            padding-top: 17.563em;
            .section_extra {
                .img_2 {
                    right: -35.625em;
                    -webkit-transform: scale(0.75);
                    transform: scale(0.75);
                    top: -0.875em;
                }
                .img_4 {
                    -webkit-transform: scale(0.75);
                    transform: scale(0.75);
                    right: 0.5em;
                    top: 22.5em;
                }
                .img_5 {
                    -webkit-transform: scale(0.75);
                    transform: scale(0.75);
                    top: 9.875em;
                    left: -13em;
                }
                .img_1,
                .img_1_1 {
                    top: 25.313em;
                    left: 0;
                }
                .img_1 {
                    height: 519px;
                    width: 110vw;
                }
                .img_1_1 {
                    height: 225px;
                    width: 110vw;
                }
                @media only screen and (min-width: 750px) {
                    .img_1,
                    .img_1_1 {
                        left: -69em;
                    }
                }
                .img_6 {
                    right: -1em;
                    top: 3em;
                    -webkit-transform: scale(0.75);
                    transform: scale(0.75);
                }
                .img_3 {
                    display: none;
                }
            }
        }
        padding-top: 7em;
    }
}
@media (min-width: 1441px) {
    .section {
        .bottom_container {
            .bottom_inner {
                display: block;
                margin: 0 auto;
            }
        }
    }
}
