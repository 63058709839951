.section {
    position: relative;
    z-index: 1;
    margin-top: -38.813em;
    margin-bottom: -4.625em;
    padding-bottom: 8em;
    overflow: hidden;
    padding-top: 10em;
    .main_services {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-transform: rotate(-44.9deg);
        transform: rotate(-44.9deg);
        -moz-transform: rotate(-44.9deg) translateZ(1px);
        -webkit-font-smoothing: antialiased;
        > div {
            background: #f5f5f5;
            border-radius: 1.438em;
            width: 22.75em;
            height: 22.75em;
            margin-bottom: 1.24em;
            > div {
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                text-align: center;
                width: 14.25em;
                height: 100%;
                margin: 0 auto;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                font-smooth: always;
                > a:not(.clear) {
                    color: #ee2a1e;
                    /*
					background-color: #ee2a1e;
                    background-image: linear-gradient(to right, #f68616 0%, #ee2a1e 100%);
					background-clip: text;
					-webkit-text-fill-color: transparent; 
					*/
                }
                > a {
                    &.clear {
                        flex: 1;
                    }
                    >.img_container {
                        width: 100%;
                        height: auto;
                        margin-top: -3.375em;
                        margin-bottom: 1.688em;
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                        &.smaller {
                            margin-top: -2.375em;
                        }
                    }
                }
                > h3 {
                    font-weight: 600;
                    font-size: 1.25em;
                    margin-bottom: 0.65em;
                }
                > p {
                    flex: 1 1;
                    font-weight: 300;
                    margin-bottom: 2.5em;
                }
            }
        }
    }
}
@media (max-width: 767.98px) {
    .section {
        margin-top: -41.813em;
        width: 100%;
        position: relative;
        .main_services {
            .active {
                > .prev {
                    content: '';
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    border-radius: 50%;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 50% 50%;
                    background-color: #fff;
                    -webkit-box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
                    box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
                    -webkit-transform: rotate(44.9deg);
                    transform: rotate(44.9deg);
                    cursor: pointer;
                    background-image: url('/static/img/icons/arrow-left.svg');
                    left: 15%;
                    top: 15%;
                }
                > .next {
                    content: '';
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    border-radius: 50%;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 50% 50%;
                    background-color: #fff;
                    -webkit-box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
                    box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
                    -webkit-transform: rotate(44.9deg);
                    transform: rotate(44.9deg);
                    cursor: pointer;
                    background-image: url('/static/img/icons/arrow-right.svg');
                    right: 15%;
                    bottom: 15%;
                }
            }
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            width: 516px;
            padding-bottom: 516px;
            position: relative;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            height: 0;
            top: -3em;
            > div {
                width: calc(100% / 1.4142);
                height: calc(100% / 1.4142);
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%) rotate(-44.9deg);
                transform: translate(-50%, -50%) rotate(-44.9deg);
                text-align: center;
                display: none;
            }
            > div.active {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
            }
        }
    }
}
@media (min-width: 768px) {
    .section {
        .main_services {
            width: 47.98em;
            -webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;
            margin: 0 auto;
            > div {
                &:nth-child(1) {
                    margin-right: 1.24em;
                }
                &:nth-child(3) {
                    margin-right: 1.24em;
                }
            }
        }
    }
}
