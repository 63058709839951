@keyframes fadeLoop {
	0% { opacity: 0.3; }
	50% { opacity: 0.8; }
	100% { opacity: 0.3; }
  }

.section {
	position: relative;
	z-index: 0;
	overflow: hidden;
	.sun_container {
		position: absolute;
		bottom: 0;
		top: 21.875em;
		left: 0;
		right: 0;
		overflow: hidden;
		z-index: 1;
        background-color: #04212c;
		.sun_background {
			width: 100%;
			height: 100%;
			z-index: 1;
		}
		.sun_image_animation {
			position: absolute;
			bottom: -15%;
			left: 50%;
			-webkit-transform: translateX(-50%);
			transform: translateX(-50%);
			z-index: 2;
			width: 1710px;
			height: 1457px;
			background: rgb(242,96,15);
			background: radial-gradient(circle, #f2600f 25%, rgba(1, 40, 49, 0.5) 50%);
			opacity: 0;
			animation-name: fadeLoop;
			animation-iteration-count: infinite;
			animation-duration: 3s;
		}
		.sun_image {
			position: absolute;
			bottom: -3% !important;
			left: 50% !important;
			transform: translateX(-50%);
			z-index: 3;
			width: 1758px !important;
			padding-left: 3em !important;
            min-height: auto !important;
            min-width: auto !important;
            top: auto !important;
            height: auto !important;
		}
	}
	.bottom_container {
		.bottom_inner {
			width: 100%;
			position: relative;
			padding-top: 43.250em;
			z-index: 5;
			width: 100%;
			padding-bottom: unset;
			padding-top: unset;
			.price_container {
				height: 0;
				position: relative;
				width: 32.250em;
				padding-bottom: 32.250em;
				margin: auto;
				.price_inner {
					font-size: 1.500em;
					font-weight: 600;
					background-image: url("/static/img/diamond-background.svg");
					background-size: 100% 100%;
					width: 15.042em;
					height: 15.042em;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-orient: vertical;
					-webkit-box-direction: normal;
					-ms-flex-direction: column;
					flex-direction: column;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
					-webkit-box-pack: center;
					-ms-flex-pack: center;
					justify-content: center;
					text-align: center;
					position: relative;
					-webkit-transform: rotate(44.9deg);
					transform: rotate(44.9deg);
					z-index: 6;
					margin: 0 auto;
					position: absolute;
					top: 30%;
					left: 50%;
					-webkit-transform: translate(-50%, -50%) rotate(-44.9deg);
					transform: translate(-50%, -50%) rotate(-44.9deg);
					width: calc(100%/1.4142);
					height: calc(100%/1.4142);
					&:after {
						content: "";
						position: absolute;
						top: 0.417em;
						bottom: 0.417em;
						left: 0.417em;
						right: 0.417em;
						border-radius: 0.958em;
					}
					div {
						-webkit-transform: rotate(45deg);
						transform: rotate(45deg);
						text-align: right;
						z-index: 7;
					}
					span {
						color: #ee2a1e;
						font-weight: 700;
						font-size: 5.000em;
						display: block;
						line-height: 1.000em;
						small {
							font-size: 0.500em;
							line-height: 1.000em;
							font-weight: 700;
						}
					}
				}
			}
		}
		width: 100%;
		position: relative;
		z-index: 2;
		.img_bg_container {
			position: absolute;
			bottom: 0;
			z-index: 4;
			width: 100%;
			height: 100%;
			padding-top: 42.563em;
			overflow: hidden;
			>svg,>img {
				position: absolute;
				bottom: 0;
				left: 50%;
				-webkit-transform: translateX(-50%);
				transform: translateX(-50%);
				float: left;
			}
		}
	}
	.top_container {
		width: 100%;
		position: relative;
		z-index: 2;
		padding-top: 19.875em;
		.img_bg {
			position: absolute;
			top: -1px;
			left: 50%;
			-webkit-transform: translateX(-50%);
			transform: translateX(-50%);
			z-index: 4;
			width: 100%;
			height: 100%;
			margin-top: 21.875em;
			@media (max-width: 767.98px) {
				margin-top: 9em;
			}
			>svg {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				float: left;
			}
		}
		.top_inner {
			position: relative;
			z-index: 5;
			width: -webkit-fit-content;
			width: -moz-fit-content;
			width: fit-content;
			margin: 0 auto;
            margin-bottom: 2em;
			ul {
				padding: 0;
				margin: 0;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				li {
					padding: 0;
					margin: 0;
					background: #F5F5F5;
					width: 7.938em;
					height: 7.938em;
					border-radius: 1.438em;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
					-webkit-box-pack: center;
					-ms-flex-pack: center;
					justify-content: center;
					> img {
						transform: rotate(44.9deg);
					}
				}
			}
			.img_container {
				position: relative;
				height: 685px;
				width: 857px;
				@media (max-width: 767.98px) {
					transform: rotate(44.9deg);
				}
				canvas {
					position: absolute;
					width: 566px;
					height: 566px;
					top: 25px;
					left: 50px;
				}
				.img_part_1 {
					position: absolute;
					right: 0;
					top: 70px;
					@media (max-width: 767.98px) {
						display: none;
					}
				}
				.img_part_2 {
					position: absolute;
					bottom: 0;
					left: 250px;
				}
				.img_part_3_svg {
					position: absolute;
					top: 25px;
					left: 50px;
				}
				.img_part_3_png {
					position: absolute;
					top: 25px;
					left: 50px;
				}
			}
			.img_mobile {
				top: -4em;
				left: 50%;
				-webkit-transform: translateX(-50%);
				transform: translateX(-50%);
				position: absolute;
			}
		}
	}
	.text_container {
		text-align: center;
		max-width: 48.563em;
		z-index: 3;
		position: relative;
		display: block;
		margin: 0 auto;
		margin-bottom: 34.563em;
		margin-top: -8em;
		h2 {
			font-size: 3.000em;
			font-weight: 600;
			color: #ffffff;
			margin-bottom: 0.313em;
		}
		p {
			font-size: 1.125em;
			font-weight: 300;
			color: #ffffff;
		}
	}
}
@media (min-width: 1441px) {
	.section {
		.bottom_container {
			.bottom_inner {
				display: block;
				margin: 0 auto;
			}
		}
	}
}
@media (max-width: 767.98px) {
	.section {
		.bottom_container {
			.bottom_inner {
				padding-top: 23.438em;
				.price_container {
					.price_inner {
						top: 24%;
					}
				}
			}
			.img_bg_container {
				.img_bg {
					bottom: 0%;
				}
			}
		}
		margin-top: 1.875em;
		overflow: hidden;
		.top_container {
			padding-bottom: 5em;
			padding-top: 8em;
			.top_inner {
				width: 780px;
				left: 50%;
				-webkit-transform: translateX(-50%);
				transform: translateX(-50%);
				padding-top: 10em;
				ul {
					-webkit-box-pack: end;
					-ms-flex-pack: end;
					justify-content: flex-end;
					z-index: 7;
					position: relative;
					-ms-flex-wrap: wrap;
					flex-wrap: wrap;
					width: 280px;
					position: absolute;
					top: 8em;
					left: 50%;
					-webkit-transform: translate(-50%, -50%) rotate(-44.9deg);
					transform: translate(-50%, -50%) rotate(-44.9deg);
					li {
						&:nth-last-of-type(2) {
							margin-left: 16px;
							margin-bottom: 16px;
						}
						img {
							-webkit-transform: rotate(44.9deg);
							transform: rotate(44.9deg);
						}
					}
				}
			}
		}
		.text_container {
			margin-top: -4em;
			margin-bottom: 6.563em;
			h2 {
				font-size: 1.750em;
			}
			p {
				font-size: 1em;
			}
		}
	}
}
@media (min-width: 768px) {
	.section {
		.top_container {
			.top_inner {
				padding-left: 5.688em;
				-webkit-transform: rotate(-44.9deg) translate(9.375em, -8.375em);
				transform: rotate(-44.9deg) translate(9.375em, -8.375em);
				ul {
					margin-left: 4.125em;
					li {
						&:not(:last-of-type) {
							margin-right: 1.375em;
						}
					}
				}
			}
		}
	}
}
@media (max-width: 516px) {
	.section {
		.bottom_container {
			.bottom_inner {
				.price_container {
					-webkit-transform: translateX(-50%);
					transform: translateX(-50%);
					left: 50%;
				}
			}
		}
	}
}
