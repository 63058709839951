.footer_animation {
    position: absolute;
    right: -37em;
    position: absolute;
    top: 21.875em;
    display: none;
}
.footer_fix_features_background_container {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 2;
    width: 100%;
    height: 100%;
    .footer_fix_features_background {
        position: absolute;
        top: -56.5em;
        left: 50%;
        transform: translateX(-50%);
    }
}
@media (min-width: 992px) {
    .footer_animation {
        display: block;
    }
}