.section_control_panel {
    margin-top: -5em;
    z-index: 0;
    overflow: visible;
    position: relative;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3em;
    @media (max-width: 1920px) {
        overflow: hidden;
    }
    .row {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .title {
        font-size: 3em;
        font-weight: 600;
        color: #00264f;
        margin-bottom: 0.375em;
        text-align: center;
    }
    .desc {
        font-size: 1.125em;
        font-weight: 300;
        color: #00264f;
        text-align: center;
        max-width: 43.111em;
        margin: 0 auto;
        display: block;
    }
    .nav_pills {
        .nav_item {
            outline: none;
            .nav_link {
                font-size: 1.125em;
                font-weight: 300;
                padding: 0.889em 1.333em;
                width: -webkit-fit-content;
                width: -moz-fit-content;
                width: fit-content;
                border-radius: unset;
                border: unset;
                color: black;
                &_active {
                    background-color: transparent;
                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='100%25' height='100%25' preserveAspectRatio='none' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3ClinearGradient x1='-20.8960364%25' y1='50.0004403%25' x2='65.6589743%25' y2='-7.02565236%25' id='linearGradient-1'%3E%3Cstop stop-color='%23F68616' offset='0%25'%3E%3C/stop%3E%3Cstop stop-color='%23EE2A1E' offset='100%25'%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3Crect id='Rectangle' x='0.5' y='0.5' width='99%25' height='98%25' fill='transparent' rx='27' stroke='url(%23linearGradient-1)' stroke-width='1' stroke-linejoin='round' %3E%3C/rect%3E%3C/svg%3E");
                    > span {
                        background: -webkit-gradient(
                            linear,
                            left top,
                            right top,
                            from(#f68616),
                            to(#ee2a1e)
                        );
                        background: linear-gradient(
                            90deg,
                            #f68616 0%,
                            #ee2a1e 100%
                        );
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
                &:focus {
                    outline: none;
                }
            }
        }
    }
    .left_shape {
        position: absolute;
        left: -42em;
        top: 50%;
        transform: translateY(-50%);
        display: none;
        @media (min-width: 1200px) {
            display: block;
        }
    }
    .tab_content {
        overflow: hidden;
        .tab_pane {
            padding-top: 10%;
            padding-bottom: 10%;
            text-align: center;
            @media (min-width: 769px) {
                padding-bottom: 100%;
            }
            > :nth-child(1) {
                position: absolute;
                @media (max-width: 1440px) {
                    top: 0;
                    bottom: 0;
                    max-height: 100%;
                }
            }
        }
    }
}
@media (max-width: 767.98px) {
    .section_control_panel {
        .title {
            font-size: 1.75em;
            width: 100%;
        }
        .desc {
            font-size: 1em;
            width: 100%;
        }
        .nav-pills {
            .nav-link {
                display: none;
            }
        }
        &:before {
            display: none;
        }
        .tab_content {
            > .prev {
                content: '';
                width: 64px;
                height: 64px;
                position: absolute;
                left: 5%;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
                &::after {
                    content: '';
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    border-radius: 50%;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 50% 50%;
                    background-color: #fff;
                    -webkit-box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
                    box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
                    background-image: url('/static/img/icons/arrow-left.svg');
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: 50%;
                }
            }
            > .next {
                content: '';
                width: 64px;
                height: 64px;
                position: absolute;
                right: 5%;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
                &::after {
                    content: '';
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: 50%;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 50% 50%;
                    background-color: #fff;
                    -webkit-box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
                    box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
                    background-image: url('/static/img/icons/arrow-right.svg');
                }
            }
        }
    }
}
@media (min-width: 769px) {
    .section_control_panel {
        // padding-bottom: 7.500em;
        .tab_content {
            .tab_pane {
                background-image: url('/static/img/control-panel-background.svg');
                background-size: 100% 100%;
                text-align: right;
            }
        }
    }
}
