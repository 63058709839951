.section {
	position: relative;
	z-index: 0;
	overflow: hidden;
	> h1.title {
		font-size: 3em;
		font-weight: 600;
		color: #00264f;
		margin-bottom: 0.375em;
		text-align: center;
	}
	> p.desc {
		font-size: 1.125em;
		font-weight: 300;
		color: #00264f;
		text-align: center;
		max-width: 43.111em;
		margin: 0 auto;
		display: block;
	}
	.background {
		background: #05272f;
        max-width: 5697px;
        margin: 0 auto;
	}
	.main_container {
		.main_services {
			position: relative;
			z-index: 5;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			@media (min-width: 769px) {
				-webkit-transform: rotate(-45deg);
				transform: rotate(-45deg);
				-moz-transform: rotate(-45deg) translateZ(1px);
			}
			> div {
				background: #f5f5f5;
				border-radius: 1.438em;
				width: 22.75em;
				height: 22.75em;
				margin-bottom: 1.24em;
				> div {
					-webkit-transform: rotate(45deg);
					transform: rotate(45deg);
					text-align: center;
					width: 14.25em;
					height: 100%;
					margin: 0 auto;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-orient: vertical;
					-webkit-box-direction: normal;
					-ms-flex-direction: column;
					flex-direction: column;
					> .icon {
						width: 9.438em;
						height: 9.438em;
						background: url("/static/img/top-icon-background.svg");
						background-size: 100% 100%;
						-webkit-transform: rotate(45deg);
						transform: rotate(45deg);
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						margin-top: -1.75em;
						margin-bottom: 4.375em;
						-ms-flex-item-align: center;
						align-self: center;
						justify-self: center;
						-webkit-box-pack: center;
						-ms-flex-pack: center;
						justify-content: center;
						> img {
							-webkit-transform: rotate(-45deg);
							transform: rotate(-45deg);
							max-width: 50%;
							height: auto;
						}
					}
					> h3 {
						font-weight: 600;
						font-size: 1.25em;
						margin-bottom: 0.65em;
					}
					> p {
						font-weight: 300;
						margin-bottom: 2.5em;
					}
				}
			}
		}
	}
	.img_bg {
		position: absolute;
		top: 19.3em;
		left: 50%;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		z-index: 3;
		@media (max-width: 768px) {
			top: 9em;
		}
	}
	.background_services {
		position: relative;
		z-index: 4;
		margin-top: 13em;
	}
	.background_diamonds {
		position: relative;
		&:before {
			top: 0;
			left: 9.375em;
		}
		&:after {
			top: 9.375em;
			right: 9.375em;
		}
	}
}
@media (max-width: 768px) {
	.section {
		> h1.title {
			width: 100%;
			font-size: 1.75em;
		}
		> p.desc {
			width: 100%;
			font-size: 1em;
		}
		.main_services {
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			width: 516px;
			padding-bottom: 516px;
			position: relative;
			left: 50%;
			-webkit-transform: translateX(-50%);
			transform: translateX(-50%);
			height: 0;
			top: -3em;
			.active {
				> .prev {
					content: "";
					width: 24px;
					height: 24px;
					position: absolute;
					border-radius: 50%;
					background-repeat: no-repeat;
					background-position: center;
					background-size: 50% 50%;
					background-color: #fff;
					-webkit-box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
					box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
					-webkit-transform: rotate(45deg);
					transform: rotate(45deg);
					cursor: pointer;
					background-image: url("/static/img/icons/arrow-left.svg");
					left: 15%;
					top: 15%;
				}
				> .next {
					content: "";
					width: 24px;
					height: 24px;
					position: absolute;
					border-radius: 50%;
					background-repeat: no-repeat;
					background-position: center;
					background-size: 50% 50%;
					background-color: #fff;
					-webkit-box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
					box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
					-webkit-transform: rotate(45deg);
					transform: rotate(45deg);
					cursor: pointer;
					background-image: url("/static/img/icons/arrow-right.svg");
					right: 15%;
					bottom: 15%;
				}
			}
			> div {
				width: calc(100% / 1.4142);
				height: calc(100% / 1.4142);
				position: absolute;
				top: 50%;
				left: 50%;
				-webkit-transform: translate(-50%, -50%) rotate(-45deg);
				transform: translate(-50%, -50%) rotate(-45deg);
				text-align: center;
				display: none;
			}
			> div.active {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
			}
		}
		.background_services {
			margin-top: 5em;
		}
	}
}
@media (min-width: 768px) {
	.section {
		.background_diamonds {
			padding-bottom: 9.375em;
			&:before {
				content: "";
				width: 9.813em;
				height: 9.813em;
				background: url("/static/img/diamond-element.svg");
				background-size: 100% 100%;
				position: absolute;
				top: 3.313em;
				left: 0;
				z-index: 5;
			}
			&:after {
				content: "";
				width: 4.438em;
				height: 4.313em;
				background: url("/static/img/diamond-element-2.svg");
				background-size: 100% 100%;
				position: absolute;
				bottom: 5em;
				right: 0;
				z-index: 5;
			}
		}
		.main_services {
			width: 47.98em;
			-webkit-box-pack: end;
			-ms-flex-pack: end;
			justify-content: flex-end;
			margin: 0 auto;
			> div {
				&:nth-child(1) {
					margin-right: 1.24em;
				}
				&:nth-child(3) {
					margin-right: 1.24em;
				}
			}
		}
	}
}
@media screen and (min-width: 768px) and (max-width: 992px) {
	.section {
		.background_diamonds {
			&:before {
				display: none;
			}
			&:after {
				display: none;
			}
		}
	}
}
